<template>
  <div>
    <v-card>
      <v-card-title class="text-h6">
        {{ $t("labels.timekeeping") }}
        <v-spacer></v-spacer>
        <v-btn
          small
          class="pr-0"
          color="success"
          text
          @click="exportExcel"
          v-if="items.length > 0"
        >
          <v-icon>mdi-download</v-icon>
          {{ $t("labels.excel") }}
        </v-btn>
        <v-btn small color="red darken-1" text @click="cancel">
          {{ $t("labels.close") }}
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div>
          <v-simple-table
            fixed-header
            height="calc(100vh - 235px)"
            class="table-padding-2"
          >
            <template v-slot:default>
              <thead class="v-data-table-header">
                <tr>
                  <th
                    role="columnheader"
                    style="max-width: 200px; min-width: 75px"
                  >
                    <SelectMultipleWarehouse
                      :label="$t('labels.warehouse')"
                      :placeholder="$t('labels.warehouse')"
                      name="id_warehouses"
                      :default-value="filters.id_warehouses"
                      @onFilter="onFilterChange"
                    />
                  </th>
                  <th
                    role="columnheader"
                    style="max-width: 250px; min-width: 95px"
                  >
                    <SelectMultipleEmployeeByWarehouse
                      :label="$t('labels.employee')"
                      :placeholder="$t('labels.employee')"
                      name="id_employees"
                      :default-value="filters.id_employees"
                      :id-warehouses="filters.id_warehouses"
                      :filters="filters"
                      @onFilter="onFilterChange"
                    />
                  </th>
                  <th
                    role="columnheader"
                    style="max-width: 200px; min-width: 95px"
                  >
                    <DateRangeFilter
                      :label="$t('labels.date')"
                      :placeholder="$t('labels.date')"
                      name="dates"
                      sort-name="dates"
                      :default-date="filters.dates"
                      :sorting="filters.sort_by"
                      @onFilter="onFilterChange"
                      @onSort="onSortChange"
                    />
                  </th>
                  <!--<SelectFilter :options="indexOptions" label="Ca" placeholder="Ca" name="index" sort-name="index" :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange"/>-->
                  <template v-if="viewOption === 'view0'">
                    <th
                      role="columnheader"
                      class="text-center"
                      style="min-width: 80px"
                    >
                      {{ $t("labels.shift") }}
                    </th>
                    <th
                      role="columnheader"
                      class="text-center"
                      style="min-width: 80px"
                    >
                      {{ $t("labels.checkin_time") }}
                    </th>
                    <th
                      role="columnheader"
                      class="text-center"
                      style="min-width: 80px"
                    >
                      {{ $t("labels.checkout_time") }}
                    </th>
                    <th
                      role="columnheader"
                      class="text-center"
                      style="min-width: 80px"
                    >
                      {{ $t("labels.timekeeping_late") }}
                    </th>
                    <th
                      role="columnheader"
                      class="text-center"
                      style="min-width: 80px"
                    >
                      {{ $t("labels.timekeeping_early") }}
                    </th>
                    <th
                      role="columnheader"
                      class="text-center"
                      style="min-width: 80px"
                    >
                      {{ $t("labels.checkin_image") }}
                    </th>
                    <th
                      role="columnheader"
                      class="text-center"
                      style="min-width: 80px"
                    >
                      {{ $t("labels.checkout_image") }}
                    </th>
                  </template>
                  <template v-if="viewOption === 'view1'">
                    <!-- Σ -->
                    <th
                      role="columnheader"
                      class="text-center"
                      style="min-width: 80px"
                    >
                      {{ $t("labels.timekeeping_late") }}
                    </th>
                    <th
                      role="columnheader"
                      class="text-center"
                      style="min-width: 80px"
                    >
                      {{ $t("labels.timekeeping_early") }}
                    </th>
                    <th
                      role="columnheader"
                      class="text-center"
                      style="min-width: 80px"
                    >
                      {{ $t("labels.not_checkout") }}
                    </th>
                  </template>
                </tr>
              </thead>
              <tbody>
                <tr class="text-center" v-for="item in items" :key="item.id">
                  <td>{{ item.warehouse_code }}</td>
                  <td>{{ item.employee_name }}</td>
                  <template v-if="viewOption === 'view0'">
                    <td>{{ formatDateTime(item.date, "DD/MM/YYYY") }}</td>
                    <td>{{ item.working_time_name }}</td>
                    <td>{{ item.checkin_time }}</td>
                    <td>{{ item.checkout_time }}</td>
                    <td>{{ item.late }}</td>
                    <td>{{ item.early }}</td>
                    <td>
                      <TimekeepingImage
                        v-if="item.checkin_image"
                        :url="item.checkin_image"
                      />
                    </td>
                    <td>
                      <TimekeepingImage
                        v-if="item.checkout_image"
                        :url="item.checkout_image"
                      />
                    </td>
                  </template>
                  <template v-if="viewOption === 'view1'">
                    <td>
                      {{ item.count_checkin }}
                      <small
                        >/ {{ item.total_shift }}
                        <span class="grey--text"
                          >{{ $t("labels.shift") }}
                        </span></small
                      >
                    </td>
                    <td>
                      {{ item.sum_late }}
                      <small class="grey--text">{{
                        $t("labels.minute")
                      }}</small>
                    </td>
                    <td>
                      {{ item.sum_early }}
                      <small class="grey--text">{{
                        $t("labels.minute")
                      }}</small>
                    </td>
                    <td>
                      {{ item.sum_miss_checkout }}
                      <small class="grey--text"
                        >{{ $t("labels.times") }}
                      </small>
                    </td>
                  </template>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-row class="pt-3">
            <v-col cols="2"></v-col>
            <v-col cols="8">
              <v-pagination
                v-model="page"
                :length="totalPage"
                :total-visible="6"
              ></v-pagination>
            </v-col>
            <v-col cols="2" class="text-right">
              <v-autocomplete
                dense
                outlined
                hide-details
                v-model="viewOption"
                :items="viewOptions"
                label="View mode"
                placeholder="View mode"
                @change="getList"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { debounce, formatDateTime, formatNumber } from "@/libs/helpers";
import { httpClient } from "@/libs/http";
import { VIEW_OPTION } from "@/libs/const";

export default {
  name: "TimekeepingList",
  components: {
    SelectMultipleWarehouse: () =>
      import("@/components/administration/SelectMultipleWarehouse"),
    SelectMultipleEmployeeByWarehouse: () =>
      import("@/components/administration/SelectMultipleEmployeeByWarehouse"),
    TimekeepingImage: () =>
      import("@/components/administration/TimekeepingImage"),
    DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
    // SelectFilter: () => import('@/components/table/SelectFilter'),
  },
  props: {
    pFilters: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    page: 1,
    totalPage: 1,
    filters: {},
    items: [],
    indexTxt: {},
    indexOptions: [],
    viewOption: "view0",
    viewOptions: [...VIEW_OPTION],
  }),
  computed: {},
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  created() {
    this.filters = { ...this.pFilters };
    const indexTxt = {
      1: this.$t("labels.morning"),
      2: this.$t("labels.afternoon"),
      3: this.$t("labels.evening"),
      0: "???",
    };
    const indexOptions = [
      {
        text: indexTxt[1],
        value: 1,
      },
      {
        text: indexTxt[2],
        value: 2,
      },
    ];
    this.indexOptions = [...indexOptions];
    this.indexTxt = { ...indexTxt };
  },
  mounted() {
    this.getList();
  },
  methods: {
    formatDateTime,
    formatNumber,
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    cancel() {
      this.$emit("cancel");
    },
    getList: debounce(function () {
      httpClient
        .post("/timekeeping/v1/list", {
          ...this.filters,
          page: this.page,
          viewMode: this.viewOption,
        })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.items = [...data.rows];
        });
    }, 500),
    async exportExcel() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.waiting_file_download"));
        return false;
      }

      this.isLoading = true;
      try {
        await httpClient.post("/export-request/v1/create", {
          params: this.filters,
          type: "timekeeping",
        });
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.file_downloading"));
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
